<template>
  <!-- <button @click="deselectRows">deselect rows</button> -->
  <div>
    <ag-grid-vue style="height: 700px" class="ag-theme-balham" :columnDefs="columnDefs" @grid-ready="onGridReady"
      :defaultColDef="defaultColDef" :autoGroupColumnDef="autoGroupColumnDef" :rowData="rowData" :treeData="true"
      :animateRows="true" :groupDefaultExpanded="groupDefaultExpanded" :getDataPath="getDataPath"></ag-grid-vue>
  </div>
</template>
     

<script>
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
export default {
  components: {
    AgGridVue,
  },
  setup() {
    // const defaultColDef = {
    //   sortable: true,
    //   resizable: true,
    //   wrapText: true,
    //   autoHeight: true,
    //   editable: false,
    //   filter: true,
    //   flex: 1,
    // };


    // var gridOptions = {
    //   columnDefs: columnDefs,
    //   rowData: rowData.value,
    //   animateRows: true,
    // };

    return {
    };
  },
  data: function () {
    return {
      columnDefs: [
        { field: 'jobTitle' }, { field: 'employmentType' }
        // { field: "id" },
        // { field: "label" },
        // { field: "dataSize" },
        // { field: "categories" },
      ],
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        sortable: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        editable: false,
        filter: true,
        flex: 1,
      },
      autoGroupColumnDef: null,
      rowData: null,
      groupDefaultExpanded: null,
      getDataPath: null,
    };
  },
  props: {
    dsID: { required: false, type: String },
  },
  created() {

    this.autoGroupColumnDef = {
      headerName: 'Organisation Hierarchy',
      minWidth: 300,
      cellRendererParams: {
        suppressCount: true,
      },
    };
    this.rowData = getData();
    // [
    //     { hasPart: ["PartVersion"], id: '1123', label: "123123123", dataSize: 1, categories: "123123123" },
    //     { hasPart: ["PartVersion", "PartVersion1"], id: "567", label: "123123123", dataSize: 1, categories: "123123123" },
    //   ];
    function getData() {
      var rowData = [
        {
          orgHierarchy: ['Erica Rogers'],
          jobTitle: 'CEO',
          employmentType: 'Permanent',
        },
        {
          orgHierarchy: ['Erica Rogers', 'Malcolm Barrett'],
          jobTitle: 'Exec. Vice President',
          employmentType: 'Permanent',
        },

        {
          orgHierarchy: ['Erica Rogers', 'Malcolm Barrett', 'Esther Baker'],
          jobTitle: 'Director of Operations',
          employmentType: 'Permanent',
        },
        {
          orgHierarchy: [
            'Erica Rogers',
            'Malcolm Barrett',
            'Esther Baker',
            'Brittany Hanson',
          ],
          jobTitle: 'Fleet Coordinator',
          employmentType: 'Permanent',
        },
        {
          orgHierarchy: [
            'Erica Rogers',
            'Malcolm Barrett',
            'Esther Baker',
            'Brittany Hanson',
            'Leah Flowers',
          ],
          jobTitle: 'Parts Technician',
          employmentType: 'Contract',
        },
        {
          orgHierarchy: [
            'Erica Rogers',
            'Malcolm Barrett',
            'Esther Baker',
            'Brittany Hanson',
            'Tammy Sutton',
          ],
          jobTitle: 'Service Technician',
          employmentType: 'Contract',
        },
        {
          orgHierarchy: [
            'Erica Rogers',
            'Malcolm Barrett',
            'Esther Baker',
            'Derek Paul',
          ],
          jobTitle: 'Inventory Control',
          employmentType: 'Permanent',
        },

        {
          orgHierarchy: ['Erica Rogers', 'Malcolm Barrett', 'Francis Strickland'],
          jobTitle: 'VP Sales',
          employmentType: 'Permanent',
        },
        {
          orgHierarchy: [
            'Erica Rogers',
            'Malcolm Barrett',
            'Francis Strickland',
            'Morris Hanson',
          ],
          jobTitle: 'Sales Manager',
          employmentType: 'Permanent',
        },
        {
          orgHierarchy: [
            'Erica Rogers',
            'Malcolm Barrett',
            'Francis Strickland',
            'Todd Tyler',
          ],
          jobTitle: 'Sales Executive',
          employmentType: 'Contract',
        },
        {
          orgHierarchy: [
            'Erica Rogers',
            'Malcolm Barrett',
            'Francis Strickland',
            'Bennie Wise',
          ],
          jobTitle: 'Sales Executive',
          employmentType: 'Contract',
        },
        {
          orgHierarchy: [
            'Erica Rogers',
            'Malcolm Barrett',
            'Francis Strickland',
            'Joel Cooper',
          ],
          jobTitle: 'Sales Executive',
          employmentType: 'Permanent',
        },
      ];
      return rowData;
    }
    this.groupDefaultExpanded = -1;
    this.getDataPath = (data) => {
      console.log(data)
      return data.orgHierarchy;
    };
    this.$watch(
      () => [this.dsID],
      () => this.fetchData(),
      { immediate: true }
    );

  },
  methods: {
    fetchData() { },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
};
</script>
     
<style></style>