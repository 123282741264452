<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
    <p class="name truncate">SST Repository</p>
    <PartDesignList></PartDesignList>
  </template>
  
  <script>
  import PartDesignList from "../components/PartDesignList.vue";
  
  export default {
    name: "PartDesign",
    components: {
      PartDesignList,
    },
  };
  </script>
  
  <style>
  </style>